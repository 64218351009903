<svelte:options tag="carousel-block"/>

<script>
    import {onMount} from "svelte";

    export let hash = 'unknown';
    $: _hash = typeof hash === 'string' ? hash : 'unknown';
    $:_images = images.filter((value, _) => value.hash == _hash);
    let currentIndex = 0;
    let translateX = 0;


    onMount(() => {
        _images = images.filter((value, _) => value.hash == _hash);
    });

    function prevSlide() {
        translateX += 100;
        currentIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;
    }

    function nextSlide() {
        translateX -= 100;
        currentIndex = currentIndex === images.length - 1 ? currentIndex : currentIndex + 1;
    }
</script>

<section class="blockitem__carouselblock">
    {#if currentIndex > 0}
        <button class="btn btn-action carousel__button prev" aria-label="Previous image" on:click={prevSlide}>
            <i class="fa-regular fa-chevron-left"></i>
        </button>
    {/if}
    <div class="carousel__images" style="transform: translateX({translateX}%)">
        {#each _images as image, index}
            <picture src="{image.image_desktop}" alt="{image.image_alt}">
                <source media="(min-width:800px)" srcset="{image.image_desktop}">
                <source media="(min-width:600px)" srcset="{image.image_tablet}">
                <img src="{ image.image_mobile }" alt="{ image.alt }"
                />
            </picture>
        {/each}
    </div>
    {#if currentIndex < _images.length - 1}
        <button class="btn btn-action carousel__button next" aria-label="Next image" on:click={nextSlide}>
            <i class="fa-regular fa-chevron-right"></i>
        </button>
    {/if}
</section>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>
